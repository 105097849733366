<template>
  <div>
    <div class="gva-search-box">
      <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
        <el-form-item>
          <el-button size="small" type="primary" icon="search" @click="onSubmit">查询</el-button>
          <el-button size="small" icon="refresh" @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="gva-table-box">
        <div class="gva-btn-list">
            <el-button size="small" type="primary" icon="plus" @click="openDialog">新增</el-button>
            <el-popover v-model:visible="deleteVisible" placement="top" width="160">
            <p>确定要删除吗？</p>
            <div style="text-align: right; margin-top: 8px;">
                <el-button size="small" text @click="deleteVisible = false">取消</el-button>
<!--                <el-button size="small" type="primary" @click="onDelete">确定</el-button>-->
            </div>
            <template #reference>
                <el-button icon="delete" size="small" style="margin-left: 10px;" :disabled="!multipleSelection.length">删除</el-button>
            </template>
            </el-popover>
        </div>
        <el-table
        ref="multipleTable"
        style="width: 100%"
        tooltip-effect="dark"
        :data="tableData"
        row-key="id"
        @selection-change="handleSelectionChange"
        >
        <el-table-column type="selection" width="55" />
        <el-table-column align="left" label="日期" width="180">
            <template #default="scope">{{ formatDate(scope.row.CreatedAt) }}</template>
        </el-table-column>
        <el-table-column align="left" label="name字段" prop="name" width="120" />
        <el-table-column align="left" label="uuid字段" prop="uuid" width="120" />
        <el-table-column align="left" label="companyUuid字段" prop="companyUuid" width="120" />
        <el-table-column align="left" label="sysCompanyId字段" prop="sysCompanyId" width="120" />
        <el-table-column align="left" label="sysUserId字段" prop="sysUserId" width="120" />
        <el-table-column align="left" label="按钮组">
            <template #default="scope">
            <el-button text icon="edit" size="small" class="table-button" @click="updateCompanyUsersFunc(scope.row)">变更</el-button>
            <el-button text icon="delete" size="small" @click="deleteRow(scope.row)">删除</el-button>
            </template>
        </el-table-column>
        </el-table>
        <div class="gva-pagination">
            <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[10, 30, 50, 100]"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            />
        </div>
    </div>
    <el-dialog v-model="dialogFormVisible" :before-close="closeDialog" title="弹窗操作">
      <el-form :model="formData" label-position="right" label-width="80px">
        <el-form-item label="name字段:">
          <el-input v-model="formData.name" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="uuid字段:">
          <el-input v-model="formData.uuid" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="companyUuid字段:">
          <el-input v-model="formData.companyUuid" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="sysCompanyId字段:">
          <el-input v-model.number="formData.sysCompanyId" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="sysUserId字段:">
          <el-input v-model.number="formData.sysUserId" clearable placeholder="请输入" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button size="small" @click="closeDialog">取 消</el-button>
<!--          <el-button size="small" type="primary" @click="enterDialog">确 定</el-button>-->
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'CompanyUsers'
}
</script>

<script setup>
import {
  createCompanyUsers,
  deleteCompanyUsers,
  deleteCompanyUsersByIds,
  updateCompanyUsers,
  findCompanyUsers,
  getCompanyUsersList
} from '@/api/companyUsers'

// 全量引入格式化工具 请按需保留
import { getDictFunc, formatDate, formatBoolean, filterDict } from '@/utils/format'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref } from 'vue'

// 自动化生成的字典（可能为空）以及字段
const formData = ref({
        name: '',
        uuid: '',
        companyUuid: '',
        sysCompanyId: 0,
        sysUserId: 0,
        })

// =========== 表格控制部分 ===========
const page = ref(1)
const total = ref(0)
const pageSize = ref(10)
const tableData = ref([])
const searchInfo = ref({})

// 重置
const onReset = () => {
  searchInfo.value = {}
}

// 搜索
const onSubmit = () => {
  page.value = 1
  pageSize.value = 10
  getTableData()
}

// 分页
const handleSizeChange = (val) => {
  pageSize.value = val
  getTableData()
}

// 修改页面容量
const handleCurrentChange = (val) => {
  page.value = val
  getTableData()
}

// 查询
const getTableData = async () => {
  //const table = await getDevModelsList({ page: page.value, pageSize: pageSize.value, ...searchInfo.value })
  const table = await service({
    url: '/api/v2/companyUsers/getByPage',
    method: "post",
    data:{ page: page.value, pageSize: pageSize.value, ...searchInfo.value }
  })
    .then((res) => {
      if (res.code === 0) {
        if (res.data.list) {
          tableData.value = res.data.list;
          total.value = res.data.total;
          page.value = res.data.page;
          pageSize.value = res.data.pageSize;
        } else {
          tableData.value = res.data;
          total.value = res.data.length;
        }
      }
    })
    .catch((e) => {
      console.log(e);
    });
};

// ============== 表格控制部分结束 ===============

// 多选数据
const multipleSelection = ref([])
// 多选
const handleSelectionChange = (val) => {
    multipleSelection.value = val
}

// 删除行
const deleteRow = (row) => {
    ElMessageBox.confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
            deleteCompanyUsersFunc(row)
        })
    }


// 批量删除控制标记
const deleteVisible = ref(false)

// 多选删除
// const onDelete = async() => {
//      const ids = [];
//   if (multipleSelection.value.length === 0) {
//     ElMessage({
//       type: "warning",
//       message: "请选择要删除的数据",
//     });
//     return;
//   }
//   multipleSelection.value &&
//     multipleSelection.value.map((item) => {
//       // console.log("item:",item)
//       ids.push(item.id);
//     });
//
//   service({
//     url: "/api/v2/companyUsers/delete",
//     method: "post",
//     data:{ids:ids},
//   })
//     .then((res) => {
//       if (res.code === 0) {
//         ElMessage({
//           type: "success",
//           message: "删除成功",
//         });
//         if (tableData.value.length === ids.length && page.value > 1) {
//           page.value--;
//         }
//         deleteVisible.value = false;
//         getTableData();
//       }
//     })
//     .catch((e) => {
//       console.log(e);
//     });
//     }

// 行为控制标记（弹窗内部需要增还是改）
const type = ref('')

// 更新行
const updateCompanyUsersFunc = async(row) => {
    //const res = await findCompanyUsers({ id: row.id })
    type.value = 'update'
    //if (res.code === 0) {
        formData.value = row
        dialogFormVisible.value = true
    //}
}

// 删除行
const deleteCompanyUsersFunc = async (row) => {
    const res = await deleteCompanyUsers({ id: row.id })
    if (res.code === 0) {
        ElMessage({
                type: 'success',
                message: '删除成功'
            })
            if (tableData.value.length === 1 && page.value > 1) {
            page.value--
        }
        getTableData()
    }
}

// 弹窗控制标记
const dialogFormVisible = ref(false)

// 打开弹窗
const openDialog = () => {
    type.value = 'create'
    dialogFormVisible.value = true
}

// 关闭弹窗
const closeDialog = () => {
    dialogFormVisible.value = false
    formData.value = {
        name: '',
        uuid: '',
        companyUuid: '',
        sysCompanyId: 0,
        sysUserId: 0,
        }
}
// 弹窗确定
// const enterDialog = async () => {
//       // console.log("formData:",formData)
//       service({
//               url: "/api/v2/companyUsers/"+type.value,
//               method: "post",
//               data: formData.value,
//             }).catch((e) => {
//               console.log(e);
//             }).then((res) =>
//             {
//               if (res.code === 0) {
//                 ElMessage({
//                   type: 'success',
//                   message: '创建/更改成功'
//                 })
//                 closeDialog()
//                 getTableData()
//               }
//             }).catch((e) => {
//               console.log(e);
//             })
//
// }
</script>

<style>
</style>
