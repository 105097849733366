import service from '@/utils/request'

// @Tags CompanyUsers
// @Summary 创建CompanyUsers
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.CompanyUsers true "创建CompanyUsers"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /sysCompanyUsers/createCompanyUsers [post]
export const createCompanyUsers = (data) => {
  return service({
    url: '/sysCompanyUsers/createCompanyUsers',
    method: 'post',
    data
  })
}

// @Tags CompanyUsers
// @Summary 删除CompanyUsers
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.CompanyUsers true "删除CompanyUsers"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /sysCompanyUsers/deleteCompanyUsers [delete]
export const deleteCompanyUsers = (data) => {
  return service({
    url: '/sysCompanyUsers/deleteCompanyUsers',
    method: 'delete',
    data
  })
}

// @Tags CompanyUsers
// @Summary 删除CompanyUsers
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除CompanyUsers"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /sysCompanyUsers/deleteCompanyUsers [delete]
export const deleteCompanyUsersByIds = (data) => {
  return service({
    url: '/sysCompanyUsers/deleteCompanyUsersByIds',
    method: 'delete',
    data
  })
}

// @Tags CompanyUsers
// @Summary 更新CompanyUsers
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.CompanyUsers true "更新CompanyUsers"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /sysCompanyUsers/updateCompanyUsers [put]
export const updateCompanyUsers = (data) => {
  return service({
    url: '/sysCompanyUsers/updateCompanyUsers',
    method: 'put',
    data
  })
}

// @Tags CompanyUsers
// @Summary 用id查询CompanyUsers
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data query model.CompanyUsers true "用id查询CompanyUsers"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /sysCompanyUsers/findCompanyUsers [get]
export const findCompanyUsers = (params) => {
  return service({
    url: '/sysCompanyUsers/findCompanyUsers',
    method: 'get',
    params
  })
}

// @Tags CompanyUsers
// @Summary 分页获取CompanyUsers列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data query request.PageInfo true "分页获取CompanyUsers列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /sysCompanyUsers/getCompanyUsersList [get]
export const getCompanyUsersList = (params) => {
  return service({
    url: '/sysCompanyUsers/getCompanyUsersList',
    method: 'get',
    params
  })
}
